.chatbot-help {
    position: fixed;
    right: 18px;
    bottom: 25px;
    z-index: 1;

    .chatbot-help-tooltip {
        position: relative;
        display: inline-block;
        //border-bottom: 1px dotted black;
    }

    .chatbot-help-tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -60px;
    }

    .chatbot-help-tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .chatbot-help-tooltip:hover .tooltiptext {
        visibility: visible;
    }

    .chatbot-help-btn{
        cursor: pointer;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        background-color: #444;;
        border-width: 3px;
        border-color: #fafafa;

        &:hover {
            background-color: #2b2b2b;
        }

        .fa-question {
            color: #fafafa;
        }
    }
}
