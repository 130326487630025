.kanban-board {
  padding: 0;
  min-height: 400px;
  width: 100%;
  overflow-x: auto;
}
.kanban-board > .kanban-inner {
  padding: 0px;
}

.card-kanbanhandle {
  float:left;
  width: 250px;
  margin-right: 5px;
  background-color: $gray-600;
}

.card-kanbanhandle .loader-icon {
  display: none;
}
.card-kanbanhandle.is-loading .loader-icon {
  display: inline-block;
}

.card-kanbanhandle .card-body {
  padding: 5px;
}
.card-kanbanhandle .card-body .kanban-dropshadow {
  display: block;
  height: 20px;
  border: 1px dashed $orange;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 3px;
  margin-bottom: 5px;
}
.card-kanbanhandle .callout h6 {
  font-size: 0.9rem;
}
.card-kanbanhandle .card-header {
  border-bottom: 0;
}
.card-kanbanhandle .card-body .callout.inthedrop {
  margin-bottom: 20px;
  background-color: darkred;
}
.card-kanbanhandle .card-body .callout .members img {
  width: 26px;
}
.card-kanbanhandle .card-body .callout hr {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}
.card-kanbanhandle .card-body .callout .members > p {
  text-align: right;
}
.card-kanbanhandle .card-body .callout {
  margin-bottom: 5px !important;
}
