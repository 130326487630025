.quitting-info {
  overflow: auto;
  clear: both;
}

.quitting-info > label {
  float: left;
  width: 100%;
}

.quitting-info > select {
  float: left;
}

.quitting-info > select {
  width: 250px;
  margin-right: 10px;
}

.quitting-info > select.quitting_amount {
  width: 120px;
}
