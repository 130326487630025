.bg-linkedin {
  color: $white;
  background-color: $color_linkedin;
  border-color: $color_linkedin;
}
a.bg-linkedin:hover {
  color: $white;
  background-color: lighten($color_linkedin, 10%);
  border-color: lighten($color_linkedin, 10%);
}
.bg-xing {
  color: $white;
  background-color: $color_xing;
  border-color: $color_xing;
}
a.bg-xing:hover {
  color: $white;
  background-color: lighten($color_xing, 10%);
  border-color: lighten($color_xing, 10%);
}
