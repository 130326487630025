
.avatar-circle {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: $gray-800;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.avatar-circle-small {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: $gray-800;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.initials {
  font-size: 23px;
  line-height: 39px;
  color: #fff;
  font-family: "BebasNeue", "Courier New", monospace;
}

.initials-small {
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  font-family: "BebasNeue", "Courier New", monospace;
}

img.profile-user-img.optin {
  border-color: green;
}

img.profile-user-img.nooptin {
  border-color: grey;
}
