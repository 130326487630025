$orange: #d6963a;
$green: #2DC425;
$red: #D92424;

.jobmatch p.text-muted {
  margin-bottom: 0.3rem;
}

.jobmatch-taglist {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jobmatch-taglist > li, .jobstatus-tag > li {
  color: #343a40;
  border: 1px solid #bdc6d0;
  border-radius: 0.2rem;
  padding: 0 5px;
  cursor: pointer;
  float: left;
  margin-top: 0.3em;
  margin-right: 5px;
}

.jobmatch-taglist > li.match {
  background-color: rgba($orange, 0.25);
}

.jobmatch-taglist > li.active {
  background-color: rgba($green, 0.25);
}

.jobmatch-taglist > li.inactive {
  background-color: rgba($red, 0.25);
}
