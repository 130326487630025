@font-face{font-family:"comic sans ms";font-style:normal;font-weight:400;src:local('Comic Sans MS'),local('ComicSansMS-Regular'),url(//allfont.ru/cache/fonts/comic-sans-ms_53a8778a817839ef2dbc5dc94ce14334.woff) format('woff'),url(//allfont.ru/cache/fonts/comic-sans-ms_53a8778a817839ef2dbc5dc94ce14334.ttf) format('truetype')}

.godmode body {
  font-family: 'comic sans ms' !important;
  color: #c2c7d0 !important;
}
.godmode .main-header a, .godmode .content-wrapper a {
  color: #20C20E !important;
}
.godmode .navbar-white,
.godmode .content-wrapper .card,
.godmode .content-wrapper .info-box,
.godmode .select2-container .select2-search--inline .select2-search__field,
.godmode .timeline-inverse>div>.timeline-item, .godmode .main-footer {
  background-color: #363636 !important;
}
.godmode .card-title, .godmode .user-block .description,
.godmode .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice,
.godmode .timeline-inverse>div>.timeline-item,
.godmode .timeline>div>.timeline-item>.timeline-header, .godmode .main-footer {
  color: #c2c7d0 !important;
}
.godmode #globalsearch input,
.godmode #globalsearch button {
  background-color: #000 !important;
  color: #20C20E !important;
}
.godmode #globalsearch .input-group {
  border: 1px solid #20C20E !important;
}

.godmode .form-control {
  background-color: #363636 !important;
  color: #c2c7d0 !important;
}

.godmode .wrapper .content-wrapper {
  background-color: #000 !important;
}
.godmode .table thead th,
.godmode .table tbody td {
  border-color: #000;
}
.godmode .card-title .input-group-append .btn {
  background-color: #363636 !important;
  color: #c2c7d0 !important;
}
.godmode .main-sidebar .brand-link i {
  display: none;
}
.godmode .main-sidebar .brand-text {
  font-family: 'comic sans ms' !important;
}


