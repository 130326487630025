.table-image {
  td, th {
    vertical-align: middle;
  }
}

// table avatar
img.table-avatar {
  border-radius: 50%;
  display: inline;
  width: 2.5rem;
}
img.table-avatar-sm {
  border-radius: 50%;
  display: inline;
  width: 1.8rem;
}

.tab-pane.p-0 .table tr:first-child td {
  border-top: 0;
}
.row-icon {
  font-size: 32px;
  color: $gray-800;
}
.row-icon-sm {
  font-size: 26px;
  color: $gray-800;
}

.open-cv {
  cursor: pointer;
}

#jobs-list .bg-info {
  background-color: $orange !important;
  color: $white !important;
}
