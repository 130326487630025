#candidate-details textarea, div[role=dialog] textarea {
  min-height: 150px !important;
}

.is-invalid-important {
  border-color: #dc3545 !important;
  padding-right: 2.25rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(1.25em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.is-invalid-important-noicon {
  border-color: #dc3545 !important;
  padding-right: 2.25rem !important;
}

.select2 + .invalid-feedback {
  display: block !important;
}

hr {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.comp-data-selected {
  background-color: #a6e398;
}

.quill-input-error {
  display: block;
  height: 16px;
  width: 400px;
  font-size: 80%;
  color: #dc3545;
}

.form-input-error {
  color: #dc3545;
}

.summernote-infotext {
  margin-bottom: 0.5rem;
}


/* candidate email editor hide link target and title */
.trumbowyg-modal .trumbowyg-modal-box form label:nth-of-type(3){
  display:none;
}

.trumbowyg-modal .trumbowyg-modal-box form > label:nth-of-type(4){
  display:none;
}
