.dropdown-msg:before, .dropdown-msg:after {
  right: 112px;
}
.dropdown-msg {
  right: -100px;
}
.dropdown-alert:before, .dropdown-alert:after {
  left: 203px;
}
.dropdown-alert {
  right: -55px;
}
.dropdown-usermenu:before, .dropdown-usermenu:after {
  left: 250px;
}
.dropdown-usermenu {
  right: 0px;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  background: #fff;
  border: 1px solid #d6d6d6;
}
.navbar-expand .navbar-nav .dropdown-menu:after, .navbar-expand .navbar-nav .dropdown-menu:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.navbar-expand .navbar-nav .dropdown-menu:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.navbar-expand .navbar-nav .dropdown-menu:before {
  border-color: rgba(214, 214, 214, 0);
  border-bottom-color: #d6d6d6;
  border-width: 11px;
  margin-left: -11px;
}

#header-menu > .nav-item > .nav-link:hover {
  background-color: #f5f5f5;
}
#header-menu > .nav-item > .nav-link {
  border-radius: 4px;
}
