.candidate-address {
  overflow: auto;
  clear: both;
}

.candidate-address > label {
  float: left;
  width: 100%;
}

.candidate-address > input {
  float: left;
  width: 250px;
  margin-right: 10px;
}

.candidate-address .candidate-number {
  width: 50px;
}

.candidate-address .candidate-zip {
  width: 100px;
}

.search-highlight {
  padding: 3px 0px;
  border-top: 1px dashed #ccc;
}

.search-highlight ul {
  padding: 0px 14px;
  list-style-type: square;
}

.search-highlight li em {
  background-color: #FFE569;
  padding: 0px 3px;
  font-style: normal;
}
