.dropdown-item .media > i {
  font-size: 2rem;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 2px;
  color: #6c757d;
}
.dropdown-item-reminder, .dropdown-item-message {
  cursor: pointer;
}
.dropdown-item-reminder {
  white-space: normal;
  margin-right: 0.5rem !important;
}

.dropdown-item-reminder h3 {
  overflow: hidden;
}

.reminder-task-icon {
  width: auto;
}

.dropdown-item-title .hide-message {
  padding: 6px;
  margin: -6px;
}
.dropdown-menu .info-msg-wrapper-body {
  overflow-y: auto;
  max-height: 500px;
}
.headerinfo-loader {
  text-align: center;
  font-size: 24px;
  padding: 4px;
  opacity: 0.7;
}
