.address-map {
  height:0px;
  width:100%;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}
.map-initiated {
  height: 160px;
}
.address-wrapper p {
  margin-bottom: 0;
}
.google-autocomplete {

}

.pac-container {
  z-index: 4000;
  border: 1px solid #ced4da;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.pac-container:after {
  background-image: none !important;
  height: 0px !important;
}
.pac-item {

}
.pac-item-selected .pac-icon {

}
.pac-item-selected {
  background-color: $gray-200;
}
