$orange: #D6963A;
$black: #000000;
$gray-800: #363636;
$gray-600: #ced4da;
$gray-400: #d9d9d9;
$gray-600: #c9cacd;
$gray-200: #edefef;
$white: #ffffff;
$color_linkedin: #0077b5;
$color_xing: #007575;
$color_danger: #d32535;
$color_gray_light: #dee2e6;
$color_success: #00a65a;
