
/*
.select2-selection.select2-selection--multiple, input.form-datepicker{
  height: 38px !important;
}
*/

#filter-jobstatus-history .select2-container--bootstrap4 .select2-selection--single {
  height: auto !important;
}
.main-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;

  .bar {
    background-color: #d6963a;
    width:var(--bar-value);
    height: var(--bar-value);
    align-self:flex-end;
    margin:0 auto;
    position:relative;

    &.bar:hover{
      opacity:0.7;
    }
  }

  &.horizontal{
    flex-direction:column;
    border-bottom:none;
    .bar{
      height:20px;
      width: 0px;
      align-self:flex-start;
      margin:auto 0 auto 0;
      justify-content: center;
      align-items: center;
      display:flex;
      transition: width cubic-bezier(0.51, -0.05, 0.37, 1.28) 1.5s;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .bar.expanded-main-bars{
      width: var(--bar-value) !important;
    }

  }
}
.subbars{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}

.subbars .bar{
  display:none;
  width: 0px !important;
  height: 15px !important;
}

.subbars-container{
  display: none;
}

.subbars-container.subbar-expanded{
  display: block;
}

.subbars-container.subbar-expanded.bar-expand .subbars .bar{
  width: var(--bar-value) !important;
}

.subbars-container.subbar-expanded .subbars .bar{
  display: flex;
}

.graph-separator{
  margin:0px;
  margin-bottom: 10px;
}

.bar-title-side{
  margin-right: 15px;
  width: 20%;
}

.dashboard-tooltiptext {
  visibility: hidden;
  width: 240px;
  color: #000;
  text-align: center;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
  border-radius: 0.25rem;
  background: #fff;
  min-height: 80px;
  padding: 0.5rem;
}

.dashboard-tooltip:hover .dashboard-tooltiptext {
  visibility: visible;
}

.subbar-title{
  margin-top:15px;
}

.mouseover-grafic-cont{
  height: 50px;
  width: 70px;
  border: 1px solid black;
  border-radius: 2px;

  transform: translate(0px, -50px);
  position: absolute;
}

.mouseover-heading{
  background-color: #f5f5f5;
}

.form-group-candidatejobstatus_recruiter{
  width: 20%;
}

.form-group-candidatejobstatus_job{
  width: 20%;
}

.form-group-candidatejobstatus_status{
  width: 20%;
  margin-right: 1em;
}

.form-group-job_accumulatestatus{
  width: 20%;
}

.subbars-container{
background-color: #f5f5f5;
}

.multigrafic-text{
  margin: 15px;
}
