.btn-comment {
  /*
  position: relative;
  top: -36px;
  right: 4px;
  opacity: 0;
  */
  margin: 4px;
  height: 32px;
}
.form-group-comment {
  /*
  margin-bottom: -17px;
  */
  display: flex;
  justify-content: flex-start;
}
.form-group-comment textarea:focus + .btn-comment, .btn-comment:focus {
  opacity: 1;
}
