#dndupload-drop {
  border: 2px dashed $gray-600;
  border-radius: 10px;
  margin: 5px 0px;
  height: 100px;
  padding: 20px;
}

#dndupload-drop.highlight {
  border-color: $orange;
}

#dndupload-drop > input {
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  height: 0.1px;
  width: 0.1px;
}

.dndupload-add {
  width: 100%;
  display: block;
  height: 30px;
  font-size: 1.5em;
  color: $gray-600;
  margin-top: 10px;
  text-align: center;
}

.dndupload-uploading {
  width: 100%;
  display: none;
  height: 30px;
  margin-top: 10px;
  font-size: 1.5em;
  color: $gray-600;
  text-align: center;
}

#dndupload-drop label {
  font-weight: normal;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: $gray-600;
}
