.card-title.collapse-link:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f105";
  margin-right: 8px;
  transform: rotate(90deg) ;
  transition: all linear 0.25s;
}
.card-title.collapse-link.collapsed:before {
  transform: rotate(0deg) ;
}
.card-title {
  font-weight: bold;
  color: $black;
}
a.card-title:hover {
  color: $gray-800;
}
