.chart {
  margin-bottom: 20px;
}

.statistics-filter > form {
  display: flex;
}

.statistics-filter {
  display: block;
  /*margin: 15px auto;*/
  /*max-width: 800px;*/
  width: 100%;
}

.statistics-filter button {
  height: 38px;
  margin-top: 30px;
}

#candidates-recruiterjobs-table {
  table-layout: fixed;
  width: 100%;
}

#candidates-recruiterjobs-table td {
  cursor: pointer;
}

tr.childrow {
  border-left: 1px solid $orange;
  border-right: 1px solid $orange;
}

tr.childrow > td {
  border-top: 0px !important;
  border-bottom: 1px solid $orange;
  padding: 0px !important;
}

tr.shown {
  border-left: 1px solid $orange;
  border-right: 1px solid $orange;
}

table.childrow-chart {
  width: 100%;
}

table.childrow-chart td {
  border-top: 0px !important;
}
