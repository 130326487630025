ul.fulltext-search-result-context {
  list-style-type: none;
  padding-left: 0px;
}

ul.fulltext-search-result-context li {
  list-style-type: none;
}

ul.fulltext-search-result-context li h1 {
  font-size: 1.1rem;
  margin-bottom: 3px;
}

.fulltext-search-term {
  background-color: $orange;
  padding: 0px 2px;
}

#candidates-filter .btn-primary {
  margin-right: 10px;
}
