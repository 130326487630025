@import "./variables";
@import "./fonts";
@import "./buttons";
@import "./brand";
@import "./sidebar";
@import "./dropdowns";
@import "./navbar";
@import "./table";
@import "./cards";
@import "./backgrounds";
@import "./datatables";
@import "../../plugins/select2/css/select2.css";
@import "./select2";
@import "./colorpicker";
@import "./cursor";
@import "./trumbowyg";
/*@import "./summernote";*/
@import "../../plugins/summernote/summernote.min.css";
@import "./comment";
@import "./toast";
@import "./filemanager";
@import "./daterangepicker";
@import "./helper";
@import "./scroll";
@import "./avatar";
@import "./quittinginfo";
@import "./candidatecvdata";
@import "./tags";
@import "./form";
@import "./custom_barchart";
@import "./grouplist";
@import "./badges";
@import "./leaflet";
@import "./locationpicker";
@import "./modal";
@import "./bootstrap-switch";
@import "./quill";
@import "./quill-snow";
@import "./quill-custom";
@import "./vertical_inputgroup";
@import "./easter";
@import "./animations";
@import "./timeline";
@import "./jstree";
@import "./statistics";
@import "./infosystem";
@import "./chatbot";
@import "./search";
@import "./nav_links";
@import "./dndupload";
@import "./kanban";
@import "./cvdata";
@import "./subform";
@import "../../plugins/trumbowyg/plugins/table/ui/sass/trumbowyg.table";
@import "./matching";
@import "./chronik";
@import "../../plugins/bootstrap4-toggle/css/bootstrap4-toggle.min.css";
/*@import "../../plugins/quill-2.0.0-dev.3/quill.snow.min.css";*/
/*@import "../../plugins/quill-better-table/quill-better-table.css";*/
/*@import "../../plugins/quill-table-ui/index.css";*/
