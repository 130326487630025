.product-info {
  margin-left: 0px !important;
}

.seen {
  background-color: green;
}

.badge.jobstatus {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 3px 5px;
}
