.select2-container--bootstrap4 .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px) !important; }
.select2-container--bootstrap4 .select2-selection--single .select2-selection__placeholder {
  color: #757575;
  line-height: calc(1.5em + 0.75rem); }
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  right: 3px;
  width: 20px; }
.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
  top: 60%;
  border-color: #343a40 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  width: 0;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute; }
.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
  line-height: calc(1.5em + 0.75rem); }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.select2-results__message {
  color: #6c757d; }

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: calc(1.5em + 0.75rem + 2px) !important; }
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  color: #343a40;
  border: 1px solid #bdc6d0;
  border-radius: 0.2rem;
  padding: 0;
  padding-right: 5px;
  cursor: pointer;
  float: left;
  margin-top: 0.3em;
  margin-right: 5px; }
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove {
  color: #bdc6d0;
  font-weight: bold;
  margin-left: 3px;
  margin-right: 1px;
  padding-right: 3px;
  padding-left: 3px;
  float: left; }
.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #343a40; }

.select2-container {
  display: block; }
.select2-container *:focus {
  outline: 0; }

.input-group .select2-container--bootstrap4 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.select2-container--bootstrap4 .select2-selection {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%; }

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border-color: $orange;
  //-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select2-container--bootstrap4.select2-container--focus.select2-container--open .select2-selection {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--bootstrap4.select2-container--disabled .select2-selection, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-selection {
  background-color: #e9ecef;
  cursor: not-allowed;
  border-color: #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none; }

.select2-container--bootstrap4.select2-container--disabled .select2-search__field, .select2-container--bootstrap4.select2-container--disabled.select2-container--focus .select2-search__field {
  background-color: transparent; }

select.is-invalid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:invalid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #dc3545; }

select.is-valid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated select:valid ~ .select2-container--bootstrap4 .select2-selection {
  border-color: #28a745; }

.select2-container--bootstrap4 .select2-dropdown {
  border-color: $orange;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
  border-top: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.select2-container--bootstrap4 .select2-dropdown .select2-results__option[aria-selected=true] {
  background-color: #e9ecef;
  display: none;}

.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4 .select2-results__option--highlighted.select2-results__option[aria-selected=true] {
  background-color: $orange;
  color: #f8f9fa; }

.select2-container--bootstrap4 .select2-results__option[role=group] {
  padding: 0; }

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 15em;
  overflow-y: auto; }

.select2-container--bootstrap4 .select2-results__group {
  padding: 6px;
  display: list-item;
  color: #6c757d; }

.select2-container--bootstrap4 .select2-selection__clear {
  width: 1.2em;
  height: 1.2em;
  line-height: 1.15em;
  padding-left: 0.3rem;
  margin-top: 0.5em;
  border-radius: 100%;
  background-color: #6c757d;
  color: #f8f9fa;
  float: right;
  margin-right: 0.3rem; }
.select2-container--bootstrap4 .select2-selection__clear:hover {
  background-color: #343a40; }

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__rendered {
  margin: 2px 0;
}


.select2-container--globalsearch .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
.select2-container--globalsearch .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }
.select2-container--globalsearch .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }
.select2-container--globalsearch .select2-selection--single .select2-selection__placeholder {
  color: #999; }
.select2-container--globalsearch .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }
.select2-container--globalsearch .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--globalsearch[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--globalsearch[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--globalsearch.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
.select2-container--globalsearch.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--globalsearch.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--globalsearch .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }
.select2-container--globalsearch .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--globalsearch[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--globalsearch[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--globalsearch[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--globalsearch[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--globalsearch.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--globalsearch.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--globalsearch.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--globalsearch.select2-container--open.select2-container--above .select2-selection--single, .select2-container--globalsearch.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--globalsearch.select2-container--open.select2-container--below .select2-selection--single, .select2-container--globalsearch.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--globalsearch .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--globalsearch .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--globalsearch .select2-results > .select2-results__options {
  max-height: 600px;
  overflow-y: auto; }

.select2-container--globalsearch .select2-results__option[role=group] {
  padding: 0; }

.select2-container--globalsearch .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--globalsearch .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--globalsearch .select2-results__option .select2-results__option {
  padding-left: 1em; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }
.select2-container--globalsearch .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--globalsearch .select2-results__option--highlighted[aria-selected] {
  background-color: $orange;
  color: white; }

.select2-container--globalsearch .select2-results__option--highlighted[aria-selected] .text-muted {
  color: white !important;
}

.select2-container--globalsearch .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--globalsearch {

}
#globalsearch {
  width: 100%;
}
#globalsearch > .input-group-sm > .select2.select2-container.select2-container--globalsearch {
  width: 90% !important;
}
#globalsearch > .input-group-sm {
  width: 100%;
}
#globalsearch .select2-selection.select2-selection--multiple {
  border: 0;
  border-radius-right: 0px;
  background-color: #f5f5f5;
}
.select2-container--globalsearch .select2-dropdown.select2-dropdown--below {
  border:0 !important;
  background-color: transparent;
}
.select2-container--globalsearch .select2-results__options {
  margin-right: -29px !important;
  margin-top: -2px;
  background-color: #f5f5f5;
  border-radius-bottom: 4px;
  -webkit-box-shadow: 0px 5px 9px -5px rgba(0,0,0,0.9);
  box-shadow: 0px 5px 9px -5px rgba(0,0,0,0.9);
}
.select2-container--globalsearch .select2-results__option {
  background-color: $white;
  margin: 0.6rem;
  padding: 0.8rem;
  border-radius: 5px;
}
.select2-container--globalsearch .select2-results__option .score {
  float: right;
}
.select2-container--globalsearch .select2-results__option em {
  font-style: normal;
  font-weight: bold;
}

/* custom styles */
.select2-results__group, .select2-results__option {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 16px !important;
}

.select2-results__options[role=listbox] {
  max-height: 300px !important;
}

.select2-selection__rendered {
  font-size: 16px !important;
}

.select2-selection--single {
  height: 38px !important;
  border-color: rgb(206, 212, 218) !important;
}

.select2-selection__arrow {
  height: 38px !important;
}

.select2-container {
  width: 100% !important;
}


.select2-selection__arrow {
  margin-top: -22px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 23px !important;
}
