ul.checkboxgroup, ul.checkboxgroup > li > ul {
  display: block;
  padding-inline-start: 0;
  padding: 0;
  margin: 0;
}
ul.checkboxgroup > li {
  display: block;
  width: 230px;
  min-height: 200px;
  float: left;
}
ul.checkboxgroup > li:last-child::after {
  content: '';
  display: block;
  clear: left;
}
ul.checkboxgroup > li > ul {
  margin-bottom: 15px;
  padding-bottom: 15px;
}
ul.checkboxgroup label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
