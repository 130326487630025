.nav-link p .badge {
  margin-top: -3px;
}
.navbar-badge {
  font-weight: bold;
}
label.badge.has-checkbox {
  cursor: pointer;
}
label.badge.has-checkbox span {
  position: relative;
  top: -2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
