/* Margin botton */
.ql-editor p {
  margin-bottom: 22px;
}

.ql-editor ul, .ql-editor ol {
  margin-bottom: 32px;
}

/* Tell Quill not to scroll */
#scrolling-container .quill-container {
  height: auto;
  min-height: 100%;
}
#scrolling-container .quill-container .ql-editor {
  font-size: 16px;
  font-family: "Source Sans Pro";
  overflow-y: visible;
}

/* Specify our own scrolling container */
#scrolling-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
