.sidebar .nav-item.has-treeview.menu-open .nav-treeview > .nav-item > .nav-link.active {
  border-color: $orange;
}
.sidebar .nav-item.has-treeview.menu-open .nav-treeview > .nav-item > .nav-link {
  border-left: .2rem solid $gray-800;
}
.sidebar .nav-header {
  text-transform: uppercase;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: $white;
}
