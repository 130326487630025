.chronik-container{
  max-height: 60vw;
  overflow-y: scroll;
}

.chronik-val-cont{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 800px;
}

.chronik-val-cont > span{
  width:4%;
  margin-right:1%;
}
.chronik-val-cont > label{
  width:96%;

}

.changelog-expander{
  cursor: pointer;
  font-weight: 700;
}

