
.content-wrapper .dropleft .dropdown-menu.show:before
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 9px 0 9px 11px;
  border-color: transparent $white;
  display: block;
  width: 0;
  z-index: 2;
  right: -11px;
  top: 11px;
}
.content-wrapper .dropleft .dropdown-menu.show:after
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 0 10px 12px;
  border-color: transparent rgba(0,0,0,0.15);
  display: block;
  width: 0;
  z-index: 1;
  right: -12px;
  top: 10px;
}
.content-wrapper .dropdown .dropdown-menu.show:before
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 9px 11px;
  border-color: $white transparent;
  display: block;
  width: 0;
  z-index: 2;
  top: -11px;
  left: 24px;
}
.content-wrapper .dropdown .dropdown-menu.show:after
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 10px 12px;
  border-color: rgba(0,0,0,0.15) transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -12px;
  left: 23px;
}
