.text-shorter {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-duplicates {
  padding-bottom: 5px;
  text-align: center;
}

.profile-duplicates > span {
  color: $gray-800;
  cursor: pointer;
}
.is-hidden {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
