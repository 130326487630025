.main-sidebar i.brand-text {
  position: relative;
  top: 4px;
}
.main-sidebar .brand-text {
  font-family: BebasNeue;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1;
  position: relative;
  top: 3px;
  left: 3px;
  color: #fff;
}
.main-sidebar .brand-image {
  display: none;
}
.main-sidebar span.brand-text {
  font-size: 1.7rem;
}
.sidebar-collapse .main-sidebar .brand-image {
  display: inline;
}
