.table-filemanager .icon i {
  font-size: 32px;
}
.table-filemanager .icon {
  width: 60px;
}
.table-filemanager .options > .dropleft > a {
  color: $gray-400;
}
.table-filemanager td.options {
  width: 40px;
  padding-right: 0 !important;
}

.table-filemanager td.name {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-item > i.fas {
  display: inline-block;
  width: 20px;
}
.table-filemanager th {
  border-top: 0px;
  border-width: 1px !important;
  font-weight: normal;
}

.table-filemanager tbody tr{
  cursor: grabbing;
}
.table-filemanager th > i {
  margin-right: 5px;
}
.table-filemanager .navigator-headline a {
  color: $black;
}
.table-filemanager .navigator-headline a:hover {
  color: $black;
  text-decoration: underline;
}

#file-parse-status .alert-warning, #file-parse-status .alert-success {
  display: none;
}

.thumbnails {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 121px;
  position: relative;
}

.thumbnails .ribbon-wrapper {
  display: none;
}

.thumbnails .ribbon-wrapper i {
  transform: rotate(-45deg);
}

.thumbnails .desc {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #ccc;
}
.merger-checkbox{
  width: 5%;
  text-align: center;
}



